/** @jsxImportSource @emotion/react */
import Link from 'next/link'
import { Button } from 'grommet'
import { Send } from 'grommet-icons'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { routes } from 'routes'
import { addRedirectPath } from 'util/login'

export default function EmailLogin() {
  const { t } = useTranslation()
  const router = useRouter()
  return (
    <Link href={routes.magicLogin + addRedirectPath(router.query)} passHref>
      <Button
        primary
        size="small"
        icon={<Send />}
        fill="horizontal"
        color="lightgray"
        label={t('cta.login-with-link')}
      />
    </Link>
  )
}
