import React, { PropsWithoutRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonExtendedProps, Spinner } from 'grommet'

type Props = {
  fetching?: boolean
  icon?: JSX.Element
} & PropsWithoutRef<ButtonExtendedProps & JSX.IntrinsicElements['a']>
export default function SubmitFormButton({ fetching = false, icon, ...rest }: Props) {
  const { t } = useTranslation()
  return (
    <Button
      primary
      reverse
      size="large"
      type="submit"
      disabled={fetching}
      label={t('cta.create')}
      icon={fetching ? <Spinner /> : icon}
      {...rest}
    />
  )
}
