/** @jsxImportSource @emotion/react */
import { Text } from 'grommet'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { routes } from 'routes'
import { addRedirectPath } from 'util/login'
import LinkAnchor from 'components/LinkAnchor'

export default function EmailLogin() {
  const { t } = useTranslation()
  const router = useRouter()
  return (
    <Text size="small" textAlign="center">
      {t('components.email-login.already-have-account')}{' '}
      <LinkAnchor
        weight="normal"
        href={routes.login + addRedirectPath(router.query)}
        label={t('cta.login')}
      />
    </Text>
  )
}
