import { Box, BoxProps } from 'grommet'
import { PropsWithChildren } from 'react'

export default function FormBody({ children, ...rest }: PropsWithChildren<BoxProps>) {
  return (
    <Box direction="column" justify="center" margin={{ top: 'medium' }} {...rest}>
      {children}
    </Box>
  )
}
