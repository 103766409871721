import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { Facebook } from 'grommet-icons'
import { useSignIn } from 'react-supabase'
import { useTranslation } from 'next-i18next'
import { Box, Button, Layer, Spinner } from 'grommet'

import { useAuth } from 'hooks/useAuth'
import { redirectAfterLoginUrl } from 'routes'

export default function FacebookLogin() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const router = useRouter()
  const [{ error, fetching }, signIn] = useSignIn({
    options: { redirectTo: redirectAfterLoginUrl(router.query) },
  })
  const handleLogin = useCallback(async () => await signIn({ provider: 'facebook' }), [signIn])

  if (user) return null
  if (fetching) return <Spinner />
  if (error) {
    return (
      <Layer modal={false} full="horizontal" position="bottom" background="status-critical">
        <Box align="center" pad="small">
          {error?.message}
        </Box>
      </Layer>
    )
  }

  return (
    <Button
      fill
      primary
      size="small"
      color="#4267B2"
      icon={<Facebook />}
      onClick={handleLogin}
      label={t('cta.login-with-facebook')}
    />
  )
}
