import { PropsWithChildren } from 'react'
import { Box, BoxProps, Text } from 'grommet'
import { StatusGood, StatusWarning } from 'grommet-icons'

type Status = 'critical' | 'error' | 'warning' | 'ok' | 'unknown' | 'disabled'

type NotificationProps = PropsWithChildren<{ status: Status } & BoxProps>
export default function Notification({ status, children, ...rest }: NotificationProps) {
  const color = `status-${status}`
  return (
    <Box
      pad="small"
      round="xsmall"
      direction="row"
      justify="center"
      fill="horizontal"
      background={{ color, opacity: 'weak' }}
      border={{ color }}
      margin={{ top: 'small', horizontal: 'auto' }}
      {...rest}>
      <Box flex="grow" direction="row" align="center" justify="between">
        <Text size="small" textAlign="start" color={color}>
          {children}
        </Text>
        <Box margin={{ left: 'small' }}>
          {status === 'warning' ? (
            <StatusWarning size="medium" color={color} />
          ) : status === 'error' ? (
            <StatusWarning size="medium" color={color} />
          ) : status === 'ok' ? (
            <StatusGood size="medium" color={color} />
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}
