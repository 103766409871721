/** @jsxImportSource @emotion/react */
import { PropsWithChildren } from 'react'
import { Box, BoxExtendedProps, Text } from 'grommet'

import { tableHoverStyle, boxShadow } from 'util/ui-tables'

type Props = {
  title?: string
} & BoxExtendedProps

export default function PageContainer({ title, children, ...rest }: PropsWithChildren<Props>) {
  return (
    <Box
      round
      width="large"
      margin={{ vertical: 'large' }}
      css={[tableHoverStyle, { overflow: 'hidden', position: 'relative' }]}
      pad={title ? { top: '5rem', bottom: 'medium', horizontal: 'medium' } : 'medium'}
      {...rest}>
      {title && (
        <Box
          height="4rem"
          justify="center"
          background="white"
          round={{ corner: 'top', size: 'medium' }}
          css={{ position: 'absolute', inset: 0, boxShadow, zIndex: 10 }}>
          <Box css={{ position: 'relative' }}>
            <Text size="large" color="text" textAlign="center">
              {title}
            </Text>
          </Box>
        </Box>
      )}
      {children}
    </Box>
  )
}
