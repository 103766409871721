import { Box, BoxProps } from 'grommet'
import { PropsWithChildren } from 'react'

export default function FormSection({ children, ...rest }: PropsWithChildren<BoxProps>) {
  return (
    <Box direction="column" width="medium" alignSelf="center" justify="center" {...rest}>
      {children}
    </Box>
  )
}
