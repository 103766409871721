import { Box, BoxProps } from 'grommet'
import { PropsWithChildren } from 'react'

export default function FormFooter({ children, ...rest }: PropsWithChildren<BoxProps>) {
  return (
    <Box direction="row" justify="center" margin={{ vertical: 'medium' }} {...rest}>
      {children}
    </Box>
  )
}
