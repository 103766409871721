/** @jsxImportSource @emotion/react */
import { BoxExtendedProps } from 'grommet'
import { PropsWithChildren } from 'react'

import PageContainer from 'components/form/PageContainer'

import CoverSpinner from './CoverSpinner'

type Props = PropsWithChildren<BoxExtendedProps> & {
  loading?: boolean
  hideContent?: boolean
  fullscreen?: boolean
}
export default function LoadingContainer({
  children,
  loading = false,
  hideContent = false,
  fullscreen = false,
  ...rest
}: Props) {
  return (
    <PageContainer
      justify="center"
      css={{ position: fullscreen ? 'inherit' : 'relative', minHeight: '15rem' }}
      {...rest}>
      {loading && <CoverSpinner />}
      {(!loading || !hideContent) && children}
    </PageContainer>
  )
}
