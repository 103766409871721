/** @jsxImportSource @emotion/react */
import { Box } from 'grommet'

import Divider from 'components/Divider'

import EmailLogin from './EmailLogin'
import SlackLogin from './SlackLogin'
import GoogleLogin from './GoogleLogin'
import EmailSignUp from './EmailSignUp'
import TwitterLogin from './TwitterLogin'
import FacebookLogin from './FacebookLogin'
import MagicLinkLogin from './MagicLinkLogin'

type Props = {
  login?: boolean
  signup?: boolean
  magicLink?: boolean
}
export default function SocialLogins({ login = false, signup = false, magicLink = false }: Props) {
  return (
    <Box
      direction="column"
      gap="xsmall"
      width="medium"
      justify="center"
      margin={{ horizontal: 'auto', vertical: 'medium' }}>
      {signup && <EmailSignUp />}
      {login && <EmailLogin />}
      <Divider size="small" />
      {magicLink && <MagicLinkLogin />}
      <GoogleLogin />
      <FacebookLogin />
      <TwitterLogin />
      <SlackLogin />
    </Box>
  )
}
